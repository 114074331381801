$sizes: 44;

@mixin padding-classes {
  @for $i from 0 through $sizes {
    $padding: $i * 0.25rem;
    .cf-p-#{$i} {
      padding: $padding !important;
    }
    .cf-pl-#{$i} {
      padding-left: $padding !important;
    }
    .cf-pr-#{$i} {
      padding-right: $padding !important;
    }
    .cf-pt-#{$i} {
      padding-top: $padding !important;
    }
    .cf-pb-#{$i} {
      padding-bottom: $padding !important;
    }
    .cf-px-#{$i} {
      padding-left: $padding !important;
      padding-right: $padding !important;
    }
    .cf-py-#{$i} {
      padding-top: $padding !important;
      padding-bottom: $padding !important;
    }
  }
}
@include padding-classes;
