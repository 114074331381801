.element-label {
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 2.5px 0;
  font-family: 'Arial Bold';
  font-size: 11px;
  width: 710px;
  height: 96px;
  border: 0;
}

.element-label td {
  padding: 2.5px;
  font-family: 'Arial Bold';
  line-height: 1.1;
}

.element-label span {
  white-space: nowrap;
}

.element-label strong {
  font-size: 12px;
  font-weight: normal;
  font-family: 'Arial Bold';
}

.element-label h1,
.element-label h2 {
  color: #000;
  margin: 0;
  padding: 0;
  line-height: 52px;
  font-size: 48px;
  font-family: 'Arial Bold';
  white-space: nowrap;
}

.element-label img {
  max-width: 100%;
  max-height: 25px;
  padding: 2px 0;
}

.element-label .marks {
  text-align: center;
}

.element-label .marks img {
  padding: 4px 0;
}

.element-label .left {
  width: 120px;
  text-align: center;
  line-height: 1.1;
}

.element-label .right {
  line-height: 1.1;
  width: 110px;
}

.element-label .project-no {
  width: 120px;
  text-align: left;
}

.element-label .project-name {
  column-span: 3;
}

.element-label .section {
  width: 210px;
  text-align: center;
}

.element-label .regdate {
  width: 120px;
  text-align: right;
}
