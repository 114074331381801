$font-family: 'Open Sans', sans-serif;
$font-size: 14px;

$primary: #38898c;
$base-text: #4a4c4a;
$hovered-text: #323432;

$grid-text: var(--primary-color);
$grid-header-text: var(--primary-color60);
$grid-border: var(--primary-color20);
