// .k-clear-value {
//   right: 24px !important;
// }

tbody .k-master-row {
  cursor: pointer;
}

.k-textbox-container {
  width: 100%;
  padding-top: 16px;
}

.k-widget.k-combobox,
.k-widget.k-multiselect {
  width: 100%;
}

.k-widget.k-datapicker {
  width: 100% !important;
}

.k-pager-numbers > li > a {
  height: 20px;
  width: 20px;
}

/** Grid overrides */

.k-pager-numbers > li > a {
  height: 1.5rem;
  width: 1.5rem;
}

.k-animation-container {
  z-index: 5000;
}

.k-grid tr:not(.k-grid-edit-row) td {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.k-grid .k-textbox {
  font-size: 1rem;
}

.k-widget.k-grid {
  border-radius: 8px;
  overflow: hidden;
}

.k-grid .k-filtercell-operator {
  display: none;
}

.k-grid-content.k-virtual-content {
  scrollbar-width: thin;

  &::-webkit-scrollbar-track {
    background: #fff;
  }

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    box-sizing: border-box;
    border-radius: 8px;
    border: 2px solid #fff;
    background: var(--primary-color40);
  }
}

.k-grid-header {
  padding-right: 12px;
}

.k-grid-footer {
  padding: 0 !important;
}

.k-master-row.k-state-selected {
  background-color: var(--primary-color20) !important;
}
