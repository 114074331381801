.waybills {
  width: 100%;
  max-width: 1200px;
}

.waybill {
  padding: 2px;
  background-color: white;
  font-family: 'Roboto';
  color: black;
  line-height: 1.25;
  margin-bottom: 2px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.waybill table {
  width: 100%;
  margin-bottom: 15px;
}

.waybill tbody {
  border: 1px solid silver;
}

.waybill td,
.waybill th {
  padding: 2px;
  border: 0.5px solid silver;
}

.waybill input[type='text'] {
  width: 100%;
  border: none;
  margin: 0;
  padding: 0;
  background-color: lightyellow;
}

.waybill .logo {
  max-height: 20px;
  float: right;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}
