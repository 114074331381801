$sizes: 44;

@mixin margin-classes {
  @for $i from 0 through $sizes {
    $margin: $i * 0.25rem;
    .cf-m-#{$i} {
      margin: $margin !important;
    }
    .cf-ml-#{$i} {
      margin-left: $margin !important;
    }
    .cf-mr-#{$i} {
      margin-right: $margin !important;
    }
    .cf-mt-#{$i} {
      margin-top: $margin !important;
    }
    .cf-mb-#{$i} {
      margin-bottom: $margin !important;
    }
    .cf-mx-#{$i} {
      margin-left: $margin !important;
      margin-right: $margin !important;
    }
    .cf-my-#{$i} {
      margin-top: $margin !important;
      margin-bottom: $margin !important;
    }
  }
}
@include margin-classes;
