/** Open-Sans */

/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/open-sans/open-sans-v17-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Light'), local('OpenSans-Light'),
    url('../fonts/open-sans/open-sans-v17-latin-300.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/open-sans/open-sans-v17-latin-300.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../fonts/open-sans/open-sans-v17-latin-300.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/open-sans/open-sans-v17-latin-300.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../fonts/open-sans/open-sans-v17-latin-300.svg#OpenSans')
      format('svg'); /* Legacy iOS */
}
/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/open-sans/open-sans-v17-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
    url('../fonts/open-sans/open-sans-v17-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/open-sans/open-sans-v17-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../fonts/open-sans/open-sans-v17-latin-regular.woff') format('woff'),
    /* Modern Browsers */
      url('../fonts/open-sans/open-sans-v17-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../fonts/open-sans/open-sans-v17-latin-regular.svg#OpenSans')
      format('svg'); /* Legacy iOS */
}
/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/open-sans/open-sans-v17-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
    url('../fonts/open-sans/open-sans-v17-latin-600.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/open-sans/open-sans-v17-latin-600.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../fonts/open-sans/open-sans-v17-latin-600.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/open-sans/open-sans-v17-latin-600.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../fonts/open-sans/open-sans-v17-latin-600.svg#OpenSans')
      format('svg'); /* Legacy iOS */
}
/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/open-sans/open-sans-v17-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
    url('../fonts/open-sans/open-sans-v17-latin-700.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/open-sans/open-sans-v17-latin-700.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../fonts/open-sans/open-sans-v17-latin-700.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/open-sans/open-sans-v17-latin-700.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../fonts/open-sans/open-sans-v17-latin-700.svg#OpenSans')
      format('svg'); /* Legacy iOS */
}

/** Roboto */

/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/roboto/roboto-v20-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Roboto Light'), local('Roboto-Light'),
    url('../fonts/roboto/roboto-v20-latin-300.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/roboto/roboto-v20-latin-300.woff2')
      format('woff2'),
    /* Super Modern Browsers */ url('../fonts/roboto/roboto-v20-latin-300.woff')
      format('woff'),
    /* Modern Browsers */ url('../fonts/roboto/roboto-v20-latin-300.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../fonts/roboto/roboto-v20-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/roboto/roboto-v20-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto'), local('Roboto-Regular'),
    url('../fonts/roboto/roboto-v20-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/roboto/roboto-v20-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../fonts/roboto/roboto-v20-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/roboto/roboto-v20-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../fonts/roboto/roboto-v20-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/roboto/roboto-v20-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url('../fonts/roboto/roboto-v20-latin-500.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/roboto/roboto-v20-latin-500.woff2')
      format('woff2'),
    /* Super Modern Browsers */ url('../fonts/roboto/roboto-v20-latin-500.woff')
      format('woff'),
    /* Modern Browsers */ url('../fonts/roboto/roboto-v20-latin-500.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../fonts/roboto/roboto-v20-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/roboto/roboto-v20-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Roboto Bold'), local('Roboto-Bold'),
    url('../fonts/roboto/roboto-v20-latin-700.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/roboto/roboto-v20-latin-700.woff2')
      format('woff2'),
    /* Super Modern Browsers */ url('../fonts/roboto/roboto-v20-latin-700.woff')
      format('woff'),
    /* Modern Browsers */ url('../fonts/roboto/roboto-v20-latin-700.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../fonts/roboto/roboto-v20-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Arial bold';
  font-style: bold;
  font-weight: 700;
  src: local('Arial Bold'), local('Arial-Bold'),
    url('../fonts/arial/arial-bold.ttf') format('truetype'); /* Safari, Android, iOS */
}
